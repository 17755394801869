
function Purpose() {
  return (
    <section id="purpose">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center">
          <h2 className="section-heading">Purpose</h2>
          <p>The World War movie genre depicts, what once was everyday Americans, in places that would seem completely foreign to today’s Americans.  These films portray the social relationships of the soldiers just as much as the battles.  Films such as The Lost Battalion, Saving Private Ryan, Pearl Harbor, and Windtalkers and especially the mini-series Band of Brothers depict personal struggles in such a way that it would be impossible not to develop a new sense of respect for the “greatest generation” that lived through the uncertain times of the first half of the twentieth century.  The subject is so broad that it would be impossible to cover anything more then a small locale or the experiences of a small group of people.  Because these movies focus on the smaller picture of the wars most people fail to realize that there were nearly 20 million Americans involved in military service and an uncountable number involved in the civilian effort at home. This website will document five modern movies and five classics and compare them.</p>

          <p>"Classic movies" such as The Desert Fox, Tora! Tora! Tora!, Patton, To the Shores of Tripoli, and Patton take much different approaches to the movie genre. These films focused on well known leaders -- portraying them as gods among men, propaganda, or the inspiration of the American public. Patton and The Desert Fox followed the United States' General George. S Patton ("Old Blood and Guts") and Germany's Generalfeldmarschall Erwin Rommel ("The Desert Fox"), respectively. Both films presented both characters in a near-historically accurate fashion, The Desert Fox even faced criticism for being too accurate in the minds of some American critics.</p>

          <p>Tora! Tora! Tora! and The Longest Day both portray the events with people representing both the Allied and Axis sides and takes "historical accuracy" a step farther in that each character speaks the language of his country in the movie with English subtitles below. This is much different then what most modern movies would show. Tora! Tora! Tora! even goes as far as trying to correct history by not placing blame on General Walter Campbell Short and Admiral Husband Edward Kimmel. Both became scapegoats for American unpreparedness shortly after the attacks on Pearl Harbor.</p>
        </div>
      </div>
      <div className="row text-center">
        <div className="col-md-4">
          <a href="#content">
            <span className="fa-stack fa-4x">
              <i className="fa fa-circle fa-stack-2x text-primary"></i>
              <i className="fa fa-map fa-stack-1x fa-inverse"></i>
            </span></a>
          <h4 className="service-heading">Historical Accuracy</h4>
          <p className="text-muted">How accurate were the movies? Did the date they were made effect how closely they follow history?</p>
        </div>
        <div className="col-md-4">
          <a href="#size">
            <span className="fa-stack fa-4x">
              <i className="fa fa-circle fa-stack-2x text-primary"></i>
              <i className="fa fa-line-chart fa-stack-1x fa-inverse"></i>
            </span></a>
          <h4 className="service-heading">Large vs. Small</h4>
          <p className="text-muted">Take a look at the different sized casts used in war movies. </p>
        </div>
        <div className="col-md-4">
          <a href="#movies">
            <span className="fa-stack fa-4x">
              <i className="fa fa-circle fa-stack-2x text-primary"></i>
              <i className="fa fa-film fa-stack-1x fa-inverse"></i>
            </span></a>
          <h4 className="service-heading">Films</h4>
          <p className="text-muted">Reviews of the different films, both new and classics, that portray the world wars.</p>
        </div>
      </div>
    </div>
  </section>
  );
}

export default Purpose;
