import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import About from './pages/About';

const App = () => (
 <Router>
  <Routes>
  <Route path="/" element={<Home />} />
  <Route path="/about" element={<About />} />
  </Routes>
 </Router>
);
export default App;

